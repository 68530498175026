import React, { FC, useMemo } from 'react'
import cn from 'classnames'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale/ru'

import { CustomReporterType, OrderResponsesResponseDto } from 'src/shared/config/types'

import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'

import { ProgressStage } from '../ProgressStage/ProgressStage'

import styles from './CustomerMediaSections.module.scss'

interface CustomerMediaSectionsPropsType {
  orderResponse: OrderResponsesResponseDto
  visibleStages?: number[]
}

export const CustomerMediaSections: FC<CustomerMediaSectionsPropsType> = ({
  orderResponse,
  visibleStages = [1, 2, 3],
}) => {
  const isSecondSectionRunning = useMemo(() => orderResponse.status === CustomReporterType.PAID, [orderResponse.status])
  const isThirdSectionRunning = useMemo(
    () => orderResponse.status === CustomReporterType.COMPLETED_STEP_2,
    [orderResponse.status],
  )

  const showStage = (stage: number) => !visibleStages || visibleStages.includes(stage)

  return (
    <Flex flexDirection="column" gap={32}>
      {showStage(1) && (
        <Flex flexDirection="column" gap={24}>
          <h1 className={styles.Title}>1 раздел. Заказ</h1>
          <div className={styles.List}>
            <Media type="video" src={orderResponse.orderVideo} description="Видео заказа" />
            <Media type="image" src={orderResponse.orderScreenshot} description="Скриншот заказа" />
            {orderResponse.paymentScreenshot && (
              <Media type="image" src={orderResponse.paymentScreenshot} description="Скриншот оплаты" />
            )}
          </div>
          {orderResponse.firstStageTime && (
            <Field id="realsLink" label="Дата заказа:" kind="cabinet">
              <div className={styles.Text}>
                {format(orderResponse.firstStageTime, 'dd MMMM yyyy, HH:mm', { locale: ru })}
              </div>
            </Field>
          )}
        </Flex>
      )}

      {showStage(2) && (
        <>
          <hr className={styles.Separator} />
          <Flex flexDirection="column" gap={24}>
            <h1 className={styles.Title}>2 раздел. Выкуп</h1>
            <Field id="realsLink" label="Дата выхода рекламы:" kind="cabinet">
              {orderResponse.adDate ? (
                <div className={styles.Text}>{orderResponse.adDate}</div>
              ) : (
                <div className={cn(styles.Text, styles.TextPlug)}>Ещё не указано</div>
              )}
            </Field>
            <div className={styles.List}>
              <Media type="image" src={orderResponse.screenshotMyPurchases} description="Скрин раздела покупок" />
              <Media type="image" src={orderResponse.screenshotShtrihcode} description="Фото разрезанного штрихкода" />
            </div>
            {isSecondSectionRunning && <ProgressStage orderResponse={orderResponse} />}
          </Flex>
        </>
      )}

      {showStage(3) && (
        <>
          <hr className={styles.Separator} />
          <Flex flexDirection="column" gap={24}>
            <h1 className={styles.Title}>3 раздел. Реклама</h1>
            <Field id="realsLink" label="Ссылка на рилс:" kind="cabinet">
              {orderResponse.realsLink ? (
                <a className={styles.Link} href={orderResponse.realsLink} target="_blank" rel="noreferrer">
                  {orderResponse.realsLink}
                </a>
              ) : (
                <div className={cn(styles.Text, styles.TextPlug)}>Ещё не указано</div>
              )}
            </Field>
            <div className={styles.List}>
              <Media type="image" src={orderResponse.reviewsScreenshot} description="Скрин отзыва" />
              <Media type="image" src={orderResponse.storiesScreenshot} description="Скрин сторис" />
              <Media type="image" src={orderResponse.statisticsScreenshot} description="Скрин статистики на сторис" />
            </div>
            {isThirdSectionRunning && <ProgressStage orderResponse={orderResponse} />}
          </Flex>
        </>
      )}
    </Flex>
  )
}
