import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, CreateNotificationDto, HttpResponse } from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { TextareaField } from 'src/shared/ui/TextareaField'
import { TextInputField } from 'src/shared/ui/TextInputField'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './CabinetNotificationsNew.module.scss'

export const CabinetNotificationsNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  const { mutate: createNotificationMutation } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateNotificationDto
  >({
    mutationFn: (data) => AdminAPI.api.managementControllerCreateByAdmin(data),
  })

  const createNotification = useCallback(() => {
    const addMarketplaceToastId = toast.loading('Отправляем уведомление')

    createNotificationMutation(
      { image: '', video: '', title, message },
      {
        onSuccess: () => {
          toast.success('Уведомление отправлено! ✨', {
            id: addMarketplaceToastId,
          })

          setTitle('')
          setMessage('')
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при отправке уведомления', {
            id: addMarketplaceToastId,
          })
        },
      },
    )
  }, [createNotificationMutation, title, message])

  return (
    <CabinetPageLayout className={styles.Layout}>
      <header className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Создание уведомления</PageTitle>
      </header>
      <div className={styles.Main}>
        <div className={styles.Block}>
          {/* <Flex>
            <Uploader onChange={() => {}} type="image" className={styles.Image} />
          </Flex> */}
          <TextInputField
            id="title"
            kind="cabinet"
            label="Заголовок уведомления"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextareaField
            id="message"
            kind="cabinet"
            label="Текст уведомления"
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <CabinetActionButton
          className={styles.Button}
          onClick={createNotification}
          disabled={title.length < 1 && message.length < 1}
        >
          Отправить
        </CabinetActionButton>
      </div>
    </CabinetPageLayout>
  )
}
