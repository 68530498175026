import React, { createContext, useContext, useEffect, useRef } from 'react'
import { io, Socket } from 'socket.io-client'

import { useUserInfo } from 'src/shared/model/useUser'

import { NotificationType } from 'src/pages/CabinetNotifications/ui/Notification/Notification'
import Logo from 'src/shared/assets/short_logo.svg'
import { RoleType } from 'src/shared/config/types'

// const requestPermission = async () => {
//   if ('Notification' in window) {
//     const permission = await window.Notification.requestPermission()
//     if (permission === 'granted') {
//       console.log('Разрешение на отправку уведомлений получено!')
//     } else {
//       console.warn('Разрешение отклонено.')
//     }
//   } else {
//     console.error('Ваш браузер не поддерживает уведомления.')
//   }
// }

// const sendNotification = (message: string, title: string) => {
//   if ('Notification' in window && window.Notification.permission === 'granted') {
//     new window.Notification(`Новое уведомление! - ${title}`, {
//       body: message,
//       lang: 'ru',
//       icon: Logo,
//     })
//   }
// }

export const SocketContext = createContext<Socket | null>(null)

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const socketRef = useRef<Socket | null>(null)
  const { user } = useUserInfo()
  const role = user?.role as RoleType

  useEffect(() => {
    // requestPermission()

    socketRef.current = io(`${process.env.REACT_APP_SERVER_PROXY_URL}/marketiq`, {
      autoConnect: false,
      reconnectionDelayMax: 1000,
      query: {
        userId: user?.id,
        role,
      },
    })

    socketRef.current.connect()

    socketRef.current.on('connect', () => {
      console.log('Соединение с сервером установлено')
    })

    socketRef.current.on('exception', (err) => {
      console.error('Ошибка от сервера:', err)
    })

    return () => {
      socketRef.current?.disconnect()
    }
  }, [])

  return <SocketContext.Provider value={socketRef.current}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
  const context = useContext(SocketContext)
  // if (!context) {
  //   throw new Error('useSocket must be used within a SocketProvider')
  // }
  return context
}
