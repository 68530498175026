import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import { ReactComponent as ConfirmedIcon } from 'src/shared/assets/confirmed.svg'
import { ReactComponent as RejectedIcon } from 'src/shared/assets/rejected.svg'
import { ReactComponent as WaitingIcon } from 'src/shared/assets/waiting.svg'
import { ProductStatusType } from 'src/shared/config/types'

import { Flex } from '../Flex'

import styles from './Product.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

interface ProductPropsType {
  photoUrl?: string
  title?: string
  price?: number
  onClick(): void
  id: number
  children?: ReactNode
  status?: ProductStatusType
  className?: string
}

export const Product: FC<ProductPropsType> = ({ photoUrl, title, price, onClick, status, id, children, className }) => {
  return (
    <Flex className={cn(styles.Product, className)} flexDirection="column" onClick={onClick}>
      <div className={styles.Id}>{id}</div>
      {photoUrl && <img className={styles.Photo} src={photoUrl} />}
      <Flex className={styles.Body} flexDirection="column" gap={24}>
        <Flex flexDirection="column" justifyContent="space-between" flexGrow={1} gap={20}>
          {title && <span className={styles.Title}>{title}</span>}
          {price && <span className={styles.Price}>{numberFormat.format(price)} ₽</span>}
        </Flex>
        {children}
      </Flex>
      {status && (
        <div className={styles.StatusWrap}>
          {status === ProductStatusType.CONFIRMED && <ConfirmedIcon className={styles.Status} />}
          {status === ProductStatusType.REJECTED && <RejectedIcon className={styles.Status} />}
          {status === ProductStatusType.PENDING && <WaitingIcon className={styles.Status} />}
        </div>
      )}
    </Flex>
  )
}
