import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { ReactComponent as LaurelAwardLeft } from 'src/shared/assets/laurel_award_left.svg'
import { ReactComponent as LaurelAwardRight } from 'src/shared/assets/laurel_award_right.svg'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'

import { plural } from 'src/shared/lib/plural'

import { Avatar } from 'src/shared/ui/Avatar'

import { Flex } from '../Flex'

import styles from './Blogger.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

export interface BloggerPropsType {
  userId?: number
  firstName: string
  names?: string
  avatar?: string
  instagram?: string
  followersCount?: number
  coveragesCount?: number
  topics?: string[]
  children?: ReactNode
  tgUsername?: string
  reportId?: number
  status?: string
  userStatus?: string
  onClick?(): void
  comments?: { bloggers: string[]; customers: string[] }
  commentIntegration?: string
  reportUpdateAt?: string
  firstStageTime?: string
  secondStageTime?: string
  rating?: number
  completedTasksCount?: number
}

export const Blogger: FC<BloggerPropsType> = ({
  userId,
  firstName,
  names,
  avatar,
  instagram,
  followersCount,
  coveragesCount,
  onClick,
  tgUsername,
  reportId,
  status,
  userStatus,
  rating,
  completedTasksCount,
  children,
}) => {
  const formattedUsername = tgUsername?.startsWith('@') ? tgUsername.slice(1) : tgUsername

  return (
    <div className={cn(styles.Blogger, onClick && styles.withPointerCursor)} onClick={onClick}>
      <div className={styles.Blogger__wrap}>
        {reportId && <div className={styles.Id}>{reportId}</div>}

        {userId ? (
          <Link to={`${CABINET_USERS_ROUTE}/${userId}`} className={styles.Header}>
            <Avatar className={styles.Image} avatar={avatar} firstName={names || firstName} />
            <div className={styles.Info}>
              <span className={styles.Name}>
                {names || firstName}
                {userStatus === 'TEMPORARY_BLOCKED' && '⌛'}
              </span>
              <span className={styles.instagram}>{instagram}</span>
            </div>
          </Link>
        ) : (
          <div className={styles.Header}>
            <Avatar className={styles.Image} avatar={avatar} firstName={names || firstName} />
            <div className={styles.Info}>
              <span className={styles.Name}>
                {names || firstName}
                {status === 'TEMPORARY_BLOCKED' && '⌛'}
              </span>
              <span className={styles.instagram}>{instagram}</span>
            </div>
          </div>
        )}
        <div className={styles.RatingWrapper}>
          {rating ? (
            <Flex gap={5}>
              <LaurelAwardLeft />
              <div className={styles.RatingDetails}>
                <span className={styles.RatingValue}>{rating}</span>
                <span className={styles.RatingDescription}>Рейтинг</span>
              </div>
              <LaurelAwardRight />
            </Flex>
          ) : (
            ''
          )}
          {completedTasksCount ? (
            <Flex gap={5}>
              <LaurelAwardLeft />
              <div className={styles.RatingDetails}>
                <span
                  className={styles.RatingValue}
                >{`${completedTasksCount} ${plural(['задание', 'задания', 'заданий'], completedTasksCount)}`}</span>
                <span className={styles.RatingDescription}>Выполнил блогер</span>
              </div>
              <LaurelAwardRight />
            </Flex>
          ) : (
            ''
          )}
        </div>

        <div className={styles.Body}>
          <div className={styles.Item}>
            <span className={styles.Title}>Подписчики:</span>
            <span className={styles.Value}>
              {followersCount !== undefined ? numberFormat.format(followersCount) : '-'}
            </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.Title}>Средний охват:</span>
            <span className={styles.Value}>
              {coveragesCount !== undefined ? numberFormat.format(coveragesCount) : '-'}
            </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.Title}>Telegram:</span>
            {tgUsername ? (
              <a
                href={`https://t.me/${formattedUsername}`}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.Value, styles.TelegramLink)}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {'@' + tgUsername}
              </a>
            ) : (
              <span className={styles.Value}>Нет</span>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
