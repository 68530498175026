import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, RatingBodyDto } from 'src/shared/api'

export const useSendRatingMutation = (reportId: number, orderId: number) => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    RatingBodyDto
  >({
    mutationFn: (data) => AdminAPI.api.ordersControllerAddRating(reportId, orderId, data),
  })
}
