import React, { FC, useCallback } from 'react'
import { Modal } from 'src/shared/ui/Modal'

interface MediaModalProps {
  src?: string
  type: 'image' | 'video'
  description: string
  onClose(): void
}

import styles from './MediaModal.module.scss'

export const MediaModal: FC<MediaModalProps> = ({ src, type, description, onClose }) => {
  const renderContent = useCallback(() => {
    if (!src) {
      return null
    }

    return type === 'image' ? (
      <img className={styles.Image} src={src} alt={description} />
    ) : (
      <video className={styles.Video} src={src} controls />
    )
  }, [src, description, type])

  return (
    <Modal className={styles.RejectModal} title={description} size="big" onClose={onClose}>
      {renderContent()}
    </Modal>
  )
}
