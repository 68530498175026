import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, ResetBodyDto } from 'src/shared/api'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'
import { ValueField } from 'src/shared/ui/ValueField'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { useConfirmPaymentMutation } from '../../api/useConfirmPaymentMutation'
import { Block } from '../Block/Block'
import { PaymentProblemModal } from '../PaymentProblemModal/PaymentProblemModal'
import { Section } from '../Section/Section'

import styles from './WaitPayConfirmStage.module.scss'

interface WaitPayConfirmStagePropsType {
  orderId: number
  responseId: number
  paymentScreenshot?: string
  startTime: string
}

export const WaitPayConfirmStage: FC<WaitPayConfirmStagePropsType> = ({
  orderId,
  responseId,
  paymentScreenshot,
  startTime,
}) => {
  const queryClient = useQueryClient()
  const [isModalOpen, setModalOpen] = useState(false)
  const { mutate: confirmPayment, isPending: isLoading } = useConfirmPaymentMutation(orderId, responseId)

  const handleButtonClick = useCallback(() => confirmPayment(), [confirmPayment])

  const handleModalOpen = useCallback(() => setModalOpen(true), [])
  const handleModalClose = useCallback(() => setModalOpen(false), [])

  const { mutate: resetResponseMutation, isPending: isResetLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    ResetBodyDto
  >({
    mutationFn: (data) => AdminAPI.api.orderResponsesControllerPaymentReset(responseId, data),
  })

  const handleResetResponse = useCallback(
    ({ comment }: ResetBodyDto) => {
      setModalOpen(false)
      const resetResponseToastId = toast.loading('Отправляем заявку')

      resetResponseMutation(
        { comment },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orders', 'my', orderId] })
            toast.success('Заявка отправлена', { id: resetResponseToastId })
          },
          onError: (error) => {
            toast.error(error.error?.message || 'Ошибка при отправке заявки', {
              id: resetResponseToastId,
            })
          },
        },
      )
    },
    [resetResponseMutation],
  )

  return (
    <Section title="Ожидание оплаты от селлера">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Дождитесь, пока селлер переведёт вам полную стоимость оплаты за задание." />
          <ValueField label="После получения средств нажмите кнопку “Оплата получена”." />
          <Media
            className={styles.PaymentScreenshot}
            description="Скриншот оплаты"
            type="image"
            src={paymentScreenshot}
          />
          <RemainingTime time={startTime} duration={24} label="Оставшееся время:" error="Время вышло" />
        </Block>
        <CabinetButton
          className={styles.CabinetButton}
          onClick={handleButtonClick}
          disabled={isLoading || isResetLoading}
        >
          Оплата получена
        </CabinetButton>
        <CabinetButton className={styles.ProblemButton} type="button" onClick={handleModalOpen}>
          Проблема с оплатой
        </CabinetButton>
      </Flex>
      {isModalOpen && <PaymentProblemModal onClose={handleModalClose} onSubmit={handleResetResponse} />}
    </Section>
  )
}
