import React, { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { useUpload } from 'src/shared/model/useUpload'
import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, HttpResponse, UpdateCustomerDto } from 'src/shared/api'
import { CustomerProfileDto } from 'src/shared/config/types'

import { firstNameRules } from 'src/shared/lib/firstNameRules'
import { phoneRules } from 'src/shared/lib/phoneRules'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { ProfileAvatarForm } from 'src/widgets/ProfileAvatarForm'

import styles from './CustomerProfileForm.module.scss'

interface CustomerProfileFormFields {
  avatar: File | string
  name: string
  lastName: string
  inn: string
  phone: string
}
interface CustomerProfileFormProps {
  profile?: CustomerProfileDto
}

export const CustomerProfileForm: FC<CustomerProfileFormProps> = ({ profile }) => {
  const [isUploading, setIsUploading] = useState(false)
  const { logout } = useUserInfo()
  const upload = useUpload()

  const methods = useForm<CustomerProfileFormFields>({
    defaultValues: {
      avatar: profile?.avatar || '',
      name: profile?.firstName || '',
      lastName: profile?.lastName || '',
      inn: profile?.inn || '',
      phone: profile?.phone || '',
    },
  })
  const { handleSubmit } = methods

  const updateCustomerMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateCustomerDto
  >({
    mutationFn: AdminAPI.api.customersControllerUpdate,
  })

  const onSubmit = useCallback(
    async (data: CustomerProfileFormFields) => {
      const updateCustomerToastId = toast.loading('Загружаем изображение')

      try {
        let avatar: string
        if (typeof data.avatar === 'string') {
          avatar = data.avatar
        } else {
          setIsUploading(true)
          avatar = await upload(data.avatar)
          setIsUploading(false)
        }

        const updateCustomerDto: UpdateCustomerDto = {
          avatar,
          firstName: data.name,
          lastName: data.lastName,
          inn: data.inn,
        }

        toast.loading('Обновляем данные', {
          id: updateCustomerToastId,
        })

        updateCustomerMutation.mutate(updateCustomerDto, {
          onSuccess: () => {
            toast.success('Данные обновлены! ✨', {
              id: updateCustomerToastId,
            })
            const currentData = JSON.parse(localStorage.getItem('user') || '{}')

            const updatedData = {
              ...currentData,
              avatar,
              phone: data.phone,
              firstName: data.name,
              lastName: data.lastName,
            }

            localStorage.setItem('user', JSON.stringify(updatedData))
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при обновлении личной информации', {
              id: updateCustomerToastId,
            })
          },
        })
      } catch (error) {
        console.error(error)
        toast.error(String(error) || 'Произошла ошибка при обновлении личной информации', {
          id: updateCustomerToastId,
        })
        setIsUploading(false)
      }
    },
    [upload, updateCustomerMutation],
  )

  const isLoading = updateCustomerMutation.isPending || isUploading

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
        <div className={styles.FormInputs}>
          <ProfileAvatarForm name="avatar" firstName={profile?.firstName} />
          <TextInputFormField id="name" name="name" label="Имя" rules={firstNameRules} kind="cabinet" />
          <TextInputFormField id="lastName" name="lastName" label="Фамилия" kind="cabinet" />
          <TextInputFormField id="inn" name="inn" label="ИНН" kind="cabinet" />
          <PhoneInputFormField
            id="phone"
            name="phone"
            label="Номер телефона"
            placeholder="Номер телефона"
            rules={phoneRules}
            kind="cabinet"
            disabled
          />
        </div>
        <Flex alignItems="center" justifyContent="space-between" className={styles.Footer}>
          <CabinetActionButton kind="ghost" className={styles.ExitButton} onClick={logout} type="button">
            Выйти из профиля
          </CabinetActionButton>
          <CabinetButton className={styles.CabinetButton} disabled={isLoading}>
            Сохранить
          </CabinetButton>
        </Flex>
      </form>
    </FormProvider>
  )
}
