import React, { FC, useCallback, useMemo, useState } from 'react'
import { DeepPartial, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  AdminAPI,
  BankDetailsDto,
  CommonMessageResponse,
  HttpResponse,
  TopicResponseDto,
  UpdateUserByAdminDto,
  UserResponseDto,
} from 'src/shared/api'

import { getBankDetailsChange, getTopicsChanges } from 'src/shared/lib/prepareBloggerFields'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Tabs } from 'src/shared/ui/Tabs'

import { BankDetailsSection, BloggerForm, BlogInfo, MediaList, PersonalInfo } from 'src/widgets/BloggerForm'
import { BloggerFormFields } from 'src/widgets/BloggerForm/ui/BloggerForm/BloggerForm'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { Tasks } from '../Tasks/Tasks'
import { UpdateRequest } from '../UpdateRequest/UpdateRequest'

import styles from './BlogerUser.module.scss'

enum TabType {
  PROFILE = 'PROFILE',
  TASKS = 'TASKS',
  UPDATE_REQUEST = 'UPDATE_REQUEST',
}

interface BlogerUserPropsType {
  user: UserResponseDto
}

const FORM_ID = 'AdminBloggerUpdate'

export const BlogerUser: FC<BlogerUserPropsType> = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { bloger } = user
  const bankDetails = bloger?.bankDetails as unknown as BankDetailsDto[]
  const topics = bloger?.topics as unknown as TopicResponseDto[]

  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])
  const [isUploading, setIsUploading] = useState(false)

  const activeTabId = searchParams.get('tab') || TabType.PROFILE

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.PROFILE,
        title: 'Личная информация',
      },
      ...(bloger?.approveId
        ? [
            {
              tabId: TabType.UPDATE_REQUEST,
              title: 'Заявка на изменение',
            },
          ]
        : []),
      {
        tabId: TabType.TASKS,
        title: 'Задания блогера',
      },
    ],
    [bloger],
  )

  const handleTabClick = useCallback(
    (newTabId: string) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const defaultValues = useMemo<DeepPartial<BloggerFormFields>>(
    () => ({
      avatar: user.avatar || '',
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName || '',
      phone: user.phone,
      country: bloger?.country,
      city: bloger?.city,
      tgUsername: user?.tgUsername,
      instagram: bloger?.instagram,
      followersCount: bloger?.followersCount,
      coveragesCount: bloger?.coveragesCount,
      topics: topics.map((item) => ({ topicId: String(item.id) })),
      bankDetails: bankDetails,
      verifyInfo: {
        videoStatistics: bloger?.verifyInfo?.videoStatistics || '',
        genderStatistics: bloger?.verifyInfo?.genderStatistics || '',
        countriesStatistics: bloger?.verifyInfo?.countriesStatistics || '',
        lastStoryStatistics: bloger?.verifyInfo?.lastStoryStatistics || '',
        publicationStatistics: bloger?.verifyInfo?.publicationStatistics || '',
      },
    }),
    [bloger],
  )

  const methods = useForm<BloggerFormFields>({ defaultValues })

  const { mutate: updateUserMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateUserByAdminDto
  >({
    mutationFn: (data: UpdateUserByAdminDto) => AdminAPI.api.usersManagementControllerPatchUserById(user.id, data),
  })

  const updateUser = useCallback(
    async (data: BloggerFormFields) => {
      const updateUserToastId = toast.loading('Обновляем данные пользователя')
      const verifyInfo = defaultValues.verifyInfo
      const newVerifyInfo = data.verifyInfo

      const { newTopics, deletedTopics } = getTopicsChanges(topics, data.topics)
      const newBankDetails = getBankDetailsChange(bankDetails, data.bankDetails)

      function getUpdatedValue<T>(defaultValue: T, newValue: T): T | undefined {
        return defaultValue === newValue ? undefined : newValue
      }
      const updateBloggerDto: UpdateUserByAdminDto = {
        avatar: getUpdatedValue(defaultValues.avatar, data.avatar),
        email: getUpdatedValue(defaultValues.email, data.email),
        firstName: getUpdatedValue(defaultValues.firstName, data.firstName),
        lastName: getUpdatedValue(defaultValues.lastName, data.lastName),
        phone: defaultValues.phone === data.phone ? undefined : '+7' + data.phone,
        country: getUpdatedValue(defaultValues.country, data.country),
        city: getUpdatedValue(defaultValues.city, data.city),
        tgUsername: getUpdatedValue(defaultValues.tgUsername, data.tgUsername),
        instagram: getUpdatedValue(defaultValues.instagram, data.instagram),
        followersCount: getUpdatedValue(Number(defaultValues.followersCount), Number(data.followersCount)),
        coveragesCount: getUpdatedValue(Number(defaultValues.coveragesCount), Number(data.coveragesCount)),
        blogTopics: {
          create: newTopics.map(({ topicId }) => ({ topicId: Number(topicId) })),
          delete: deletedTopics.map((topicId) => ({ topicId: Number(topicId) })),
        },
        bankDetails: newBankDetails as BankDetailsDto[],
        verifyInfo: {
          videoStatistics: getUpdatedValue(verifyInfo?.videoStatistics, newVerifyInfo?.videoStatistics),
          genderStatistics: getUpdatedValue(verifyInfo?.genderStatistics, newVerifyInfo?.genderStatistics),
          countriesStatistics: getUpdatedValue(verifyInfo?.countriesStatistics, newVerifyInfo?.countriesStatistics),
          lastStoryStatistics: getUpdatedValue(verifyInfo?.lastStoryStatistics, newVerifyInfo?.lastStoryStatistics),
          publicationStatistics: getUpdatedValue(
            verifyInfo?.publicationStatistics,
            newVerifyInfo?.publicationStatistics,
          ),
        },
      }

      updateUserMutation(updateBloggerDto, {
        onSuccess: () => {
          toast.success('Пользовательские данные обновлены! ✨', {
            id: updateUserToastId,
          })
          queryClient.invalidateQueries({ queryKey: ['users', user.id] })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при обновлении пользовательских данных', {
            id: updateUserToastId,
          })
        },
      })
    },
    [updateUserMutation, bankDetails, topics],
  )

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Блогер</PageTitle>
      </Flex>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={handleTabClick} />
      {activeTabId === TabType.PROFILE && bloger && (
        <Flex flexDirection="column" gap={30} className={styles.Form}>
          <BloggerForm
            formId={FORM_ID}
            methods={methods}
            onSubmit={updateUser}
            isLoading={isLoading || isUploading}
            setIsUploading={setIsUploading}
          >
            <Flex gap={24} flexWrap="wrap" className={styles.Blocks}>
              <PersonalInfo showAvatar showEmail showNames showPhone showCountry />
              <BlogInfo showInstagram showTelegram showTopics showCoverages showFollowers />
              <BankDetailsSection autocomplete="off" />
            </Flex>

            <MediaList />
          </BloggerForm>
          <CabinetActionButton
            form={FORM_ID}
            kind="primary"
            disabled={isLoading || isUploading}
            className={styles.FormButton}
          >
            Сохранить
          </CabinetActionButton>
        </Flex>
      )}
      {activeTabId === TabType.TASKS && <Tasks user={user} />}
      {activeTabId === TabType.UPDATE_REQUEST && bloger?.approveId && (
        <UpdateRequest user={user} setActiveTabId={handleTabClick} />
      )}
    </CabinetPageLayout>
  )
}
