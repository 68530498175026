import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router'

import { useUpload } from 'src/shared/model/useUpload'

import { BankDetailsDto, ResetBodyDto } from 'src/shared/api'
import { OrderResponsesResponseDto } from 'src/shared/config/types'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { Uploader } from 'src/shared/ui/Uploader'

import { useResetResponseMutation } from '../../api/useResetResponseMutation'
import { useSetOrderPaymentMutation } from '../../api/useSetOrderPaymentMutation'
import { CustomerMediaSections } from '../CustomerMediaSections/CustomerMediaSections'
import { OrderProblemModal } from '../OrderProblemModal/OrderProblemModal'
import { ProgressStage } from '../ProgressStage/ProgressStage'

import styles from './WaitPaymentStage.module.scss'

interface WaitPaymentStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const WaitPaymentStage: FC<WaitPaymentStagePropsType> = ({ orderResponse }) => {
  const navigate = useNavigate()
  const upload = useUpload()
  const { taskId, responseId } = useParams()
  const orderId = Number(taskId)
  const reportId = Number(responseId)

  const [isModalOpen, setModalOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [image, setImage] = useState<string>()

  const handleModalOpen = useCallback(() => setModalOpen(true), [])
  const handleModalClose = useCallback(() => setModalOpen(false), [])

  const { mutate: setOrderReportPaymentMutation, isPending } = useSetOrderPaymentMutation(reportId, orderId)

  const handleSendReport = useCallback(async () => {
    if (image) {
      const setOrderReportToastId = toast.loading('Отправляем данные')

      setOrderReportPaymentMutation(
        { paymentScreenshot: image },
        {
          onSuccess: () => {
            toast.success('Данные успешно отправлены', { id: setOrderReportToastId })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при загрузке', {
              id: setOrderReportToastId,
            })
          },
        },
      )
    }
  }, [image, setOrderReportPaymentMutation])

  const { mutate: resetResponseMutation, isPending: isLoading } = useResetResponseMutation(reportId)

  const handleResetResponse = useCallback(
    ({ comment }: ResetBodyDto) => {
      setModalOpen(false)
      const resetResponseToastId = toast.loading('Отправляем заявку')

      resetResponseMutation(
        { comment },
        {
          onSuccess: () => {
            toast.success('Заявка отправлена', { id: resetResponseToastId })
            navigate(-1)
          },
          onError: (error) => {
            toast.error(error.error?.message || 'Ошибка при отправке заявки', {
              id: resetResponseToastId,
            })
          },
        },
      )
    },
    [resetResponseMutation, navigate],
  )

  const uploadMedia = useCallback(async (file: File) => {
    const uploadMediaToastId = toast.loading('Загружаем скриншот')
    setIsUploading(true)

    try {
      const uploadedFile = await upload(file)

      setImage(uploadedFile)
      toast.success('Скриншот загружен', {
        id: uploadMediaToastId,
      })
    } catch (error) {
      toast.error(String(error) || 'Ошибка при загрузке скриншота', {
        id: uploadMediaToastId,
      })
    } finally {
      setIsUploading(false)
    }
  }, [])

  const isButtonDisabled = isPending || isUploading || !image

  return (
    <>
      {orderResponse.comment && (
        <Flex flexDirection="column" gap={8} className={styles.CommentWrap}>
          <p className={styles.CommentTitle}>Комментарий от блогера:</p>
          <div className={styles.Comment}>{orderResponse.comment}</div>
          <p className={styles.CommentWarning}>❗ Обратите внимание, вы уже делали перевод данному блогеру</p>
        </Flex>
      )}
      <Flex className={styles.WaitPaymentStage} flexDirection="column">
        <CustomerMediaSections orderResponse={orderResponse} visibleStages={[1]} />
        <div className={styles.Title}>
          Блогер ждет оплату. Отправьте ее на указанные реквизиты и прикрепите скриншот оплаты
        </div>
        <Flex flexDirection="column" gap={12}>
          {orderResponse.bonusPrice && (
            <Flex flexDirection="column" gap={8} className={styles.BonusPrice}>
              <span className={styles.Label}>Дополнительная выплата:</span>
              <span className={styles.Text}>{orderResponse.bonusPrice} ₽</span>
            </Flex>
          )}
          {orderResponse.commentResponse && (
            <Flex flexDirection="column" gap={8} className={styles.Comment}>
              <p className={styles.Label}>Комментарий при отклике:</p>
              <div className={styles.Text}>{orderResponse.commentResponse}</div>
            </Flex>
          )}
        </Flex>

        {orderResponse.bloger.bankDetails.map((item: BankDetailsDto, index: number) => (
          <Flex key={index} className={styles.BankDetails} flexDirection="column" gap={24}>
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Label}>Номер карты:</span>
              <span className={styles.Text}>{item.accountNumber ?? '-'}</span>
            </Flex>
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Label}>Номер телефона:</span>
              <span className={styles.Text}>{item.accountPhone ?? '-'}</span>
            </Flex>
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Label}>Название банка:</span>
              <span className={styles.Text}>{item.bankName ?? '-'}</span>
            </Flex>
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Label}>ФИО:</span>
              <span className={styles.Text}>{item.names ?? '-'}</span>
            </Flex>
          </Flex>
        ))}

        <Field className={styles.UploaderField} id="photo" label="Прикрепите скриншот оплаты:" kind="cabinet">
          <Uploader
            placeholder="Загрузить скриншот"
            type="image"
            isDisabled={isUploading || isPending}
            file={image}
            onChange={uploadMedia}
          />
        </Field>
        <ProgressStage orderResponse={orderResponse} />
      </Flex>
      <CabinetButton
        className={styles.CabinetButton}
        disabled={isButtonDisabled || isLoading}
        onClick={handleSendReport}
      >
        Отправить отчет об оплате
      </CabinetButton>
      <CabinetButton className={styles.ProblemButton} type="button" onClick={handleModalOpen}>
        Проблема с заказом
      </CabinetButton>
      {isModalOpen && <OrderProblemModal onClose={handleModalClose} onSubmit={handleResetResponse} />}
    </>
  )
}
