import { ControllerRenderProps, FieldValues, UseControllerProps } from 'react-hook-form'

import {
  BankDetailsDto,
  BlogerOrderResponseDto,
  BlogerReportResponseDto,
  OrderByCustomerResponseDto as GeneratedOrderByCustomerResponseDto,
  OrderListResponseDto,
  OrderResponsesResponseDto as GeneratedOrderResponsesResponseDto,
  TopicResponseDto,
  VerifyInfoResponseDto,
} from '../api'

export interface PageConfigType {
  title: string
  route: string
}

export type PrepareFieldPropsType<TFieldProps> = Omit<
  TFieldProps,
  'error' | 'defaultValue' | keyof Omit<ControllerRenderProps, 'onBlur'>
>

export type ReactHookFormFieldType<TFieldValues extends FieldValues, TFieldProps extends Record<string, any>> = Omit<
  UseControllerProps<TFieldValues>,
  'control'
> &
  PrepareFieldPropsType<TFieldProps>

export enum CollectionType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export enum RoleType {
  CUSTOMER = 'CUSTOMER',
  BLOGER = 'BLOGER',
  MODERATOR = 'MODERATOR',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum OrderStatusType {
  COLLECTING = 'COLLECTING',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSE = 'PAUSE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum BloggerStatusType {
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  ACTIVATED = 'ACTIVATED',
}

export enum CustomReporterType {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  WAIT_PAY_CONFIRM = 'WAIT_PAY_CONFIRM',
  PAID = 'PAID',
  COMPLETED_STEP_2 = 'COMPLETED_STEP_2',
  COMPLETED = 'COMPLETED',
  RESET = 'RESET',
}

export enum ProductStatusType {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}

export enum UserStatusType {
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  ACTIVATED = 'ACTIVATED',
  CANCELED = 'CANCELED',
  INCOMPLETE_DATA = 'INCOMPLETE_DATA',
  TEMPORARY_BLOCKED = 'TEMPORARY_BLOCKED',
}

export interface CustomerOrderListResponseDto extends OrderListResponseDto {
  collection?: string
  marketplaceName?: string
  maxParticipants?: number
  status?: string
  customReportersStatistic?: Record<string, number>
}

export interface BloggerOrderListResponseDto extends OrderListResponseDto {
  collection?: string
  currernCountParticipants?: number
  maxParticipants?: number
  reportStatus?: string
  status?: string
}

export interface OrderByCustomerResponseDto
  extends Omit<GeneratedOrderByCustomerResponseDto, 'customReporters' | 'blogers'> {
  customReportersStatistic?: Record<string, number>
  blogers?: BlogerOrderResponseDto[]
}

export type BloggerType = any

export interface OrderResponsesResponseDto extends GeneratedOrderResponsesResponseDto {
  bloger: BloggerType
  orderVideo: string
  orderScreenshot: string
  screenshotMyPurchases: string
  screenshotShtrihcode: string
  reviewsScreenshot: string
  realsLink: string
  storiesScreenshot: string
  statisticsScreenshot: string
  paymentScreenshot?: string
  bonusPrice?: number
  ratingId?: number
  comments?: {
    bloggers: string[]
    customers: string[]
  }
}

export interface DecodedJwtToken {
  id: number
  role: RoleType
  status: UserStatusType
  customerId?: number
  blogerId?: number
  tgId?: string
  instagram?: string
  iat: number
  exp: number
}

export enum VerifyInfoType {
  countriesStatistics = 'verifyInfo.countriesStatistics',
  genderStatistics = 'verifyInfo.genderStatistics',
  lastStoryStatistics = 'verifyInfo.lastStoryStatistics',
  publicationStatistics = 'verifyInfo.publicationStatistics',
  videoStatistics = 'verifyInfo.videoStatistics',
}

export interface SignInUserResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Телефон пользователя */
  phone: string
  /** URL аватара пользователя */
  avatar?: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName?: string
  /** Телеграм пользователя */
  tgUsername?: string
  /** Статус пользователя */
  status: UserStatusType
  /** Роль пользователя в системе */
  role: RoleType
}

export enum TransactionStatusType {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}

type Transaction = {
  id: number
  customerId: number
  total: number
  previousBalance: number
  status: TransactionStatusType
  comment?: string
  createAt: string
  updateAt: string
}

export type CustomerProfileDto = SignInUserResponseDto & {
  /** Итоговое количество интеграций */
  total: number
  /** История всех транзакций */
  transactions?: Transaction[]
  /** ИНН пользователя */
  inn: string
}

export type BloggerProfileDto = SignInUserResponseDto & {
  email: string
  /** Заполненность данных для блогера */
  isFullInfo: boolean
  /** Телеграм username */
  tgUsername: string
  /** Ссылка на инстаграм */
  instagram: string
  /** Город проживания */
  city: string
  /** Страна */
  country: string
  /** Количество подписчиков */
  followersCount: number
  /** Количество охватов */
  coveragesCount: number
  /** Информация по банковским данным */
  bankDetails: BankDetailsDto[]
  /** Тематики */
  topics: TopicResponseDto[]
  /** Информация по банковским данным */
  verifyInfo: VerifyInfoResponseDto
  lastUpdateAt: string
  rating?: number
}

export interface ProductResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  marketplaceUrl?: string
  /** Ссылка на фото */
  photoUrl?: string
  /** Наименование товара */
  title?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Верификация */
  status?: ProductStatusType
  /** Актуальность товара */
  isActual?: boolean
  /** Id заказчика */
  customerId?: number
}

export interface ProductAdminResponseDto extends ProductResponseDto {
  /** Имя заказчика */
  customerName?: string
}
