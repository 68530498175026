import React, { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import cn from 'classnames'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { Rating } from 'src/shared/ui/Rating'
import { TextareaField } from 'src/shared/ui/TextareaField'

import { useSendRatingMutation } from '../../api/useSendRatingMutation'

import styles from './BloggerRatingForm.module.scss'

interface BloggerRatingFormType {
  hasRated: boolean
}

export const BloggerRatingForm: FC<BloggerRatingFormType> = ({ hasRated }) => {
  const [isRatingSend, setRatingSend] = useState(false)
  const [quality, setQuality] = useState(0)
  const [speed, setSpeed] = useState(0)
  const [adQuality, setAdQuality] = useState(0)
  const [comment, setComment] = useState('')

  const { taskId, responseId } = useParams<{ taskId: string; responseId: string }>()
  const orderId = useMemo(() => Number(taskId), [taskId])
  const reportId = useMemo(() => Number(responseId), [responseId])

  const { mutate: sendRating, isPending } = useSendRatingMutation(reportId, orderId)

  const handleSendRating = useCallback(() => {
    const sendRatingToastId = toast.loading('Отправляем оценку блогера')

    const data = {
      ratingQuality: quality,
      ratingSpeed: speed,
      ratingAdQuality: adQuality,
      comment,
    }

    sendRating(data, {
      onSuccess: () => {
        toast.success('Отзыв принят! ✨', {
          id: sendRatingToastId,
        })
        setRatingSend(true)
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Ошибка при создании отзыва', {
          id: sendRatingToastId,
        })
      },
    })
  }, [quality, speed, adQuality, comment, sendRating])

  const isRatingButtonDisabled = useMemo(
    () => !quality || !speed || !adQuality || isPending,
    [quality, speed, adQuality, isPending],
  )

  if (isRatingSend || hasRated) {
    return (
      <Flex className={styles.Rating} flexDirection="column">
        <span className={styles.RatingGratitude}>Спасибо за отзыв! ✨</span>
      </Flex>
    )
  }

  return (
    <Flex className={styles.Rating} flexDirection="column">
      <span className={styles.RatingTitle}>Оцените работу блогера:</span>

      <Flex className={styles.RatingItem}>
        <div className={styles.RatingItemTitle}>Качество работы блогера:</div>
        <Rating value={quality} onChange={setQuality} />
      </Flex>

      <Flex className={styles.RatingItem}>
        <div className={styles.RatingItemTitle}>Скорость работы блогера:</div>
        <Rating value={speed} onChange={setSpeed} />
      </Flex>

      <Flex className={styles.RatingItem}>
        <div className={styles.RatingItemTitle}>Качество рекламы:</div>
        <Rating value={adQuality} onChange={setAdQuality} />
      </Flex>

      <Flex className={cn(styles.RatingItem, styles.RatingComment)}>
        <div className={styles.RatingItemTitle}>Комментарий (необязательно):</div>
        <TextareaField id="comment" kind="cabinet" value={comment} onChange={(e) => setComment(e.target.value)} />
      </Flex>

      <CabinetButton className={styles.RatingButton} disabled={isRatingButtonDisabled} onClick={handleSendRating}>
        Отправить
      </CabinetButton>
    </Flex>
  )
}
