import React, { FC, FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, CreateTransactionDto, HttpResponse, UserResponseDto } from 'src/shared/api'
import { RoleType } from 'src/shared/config/types'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { NumericInputOnChangeHandlerType } from 'src/shared/ui/NumericInput/NumericInput'
import { NumericInputField } from 'src/shared/ui/NumericInputField'

import styles from './Balance.module.scss'

interface BalancePropsType {
  user: UserResponseDto
}

export const Balance: FC<BalancePropsType> = ({ user }) => {
  const { role } = useUserInfo()
  const queryClient = useQueryClient()

  const customerId = user.customer!.id
  const previousBalance = user.customer!.total || 1
  const [balance, setBalance] = useState(previousBalance)
  const [errorMessage, setErrorMessage] = useState<string>()

  const { mutateAsync: setCustomerBalanceMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateTransactionDto
  >({
    mutationFn: AdminAPI.api.managementControllerSetCustomerBalance,
  })

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      const setCustomerBalanceToastId = toast.loading('Обновляем баланс')

      event.preventDefault()
      if (balance <= 0) {
        setErrorMessage('Баланс должен быть положительным числом')
        return
      }

      try {
        await setCustomerBalanceMutation(
          {
            customerId,
            total: balance,
          },
          {
            onSuccess: () => {
              toast.success('Баланс обновлён! ✨', { id: setCustomerBalanceToastId })
              setErrorMessage(undefined)
              queryClient.invalidateQueries({ queryKey: ['users', user.id] })
            },
            onError: (data) => {
              toast.error(data.error?.message || 'Произошла ошибка при обновлении баланса', {
                id: setCustomerBalanceToastId,
              })
            },
          },
        )
      } catch (error) {
        setErrorMessage(undefined)
        console.error(error)
      }
    },
    [setCustomerBalanceMutation, customerId, previousBalance, balance],
  )

  const handleBalanceChange = useCallback<NumericInputOnChangeHandlerType>((_, value) => setBalance(Number(value)), [])

  return (
    <form onSubmit={handleSubmit}>
      <Flex className={styles.Balance} flexDirection="column" gap={24}>
        <div className={styles.InputsContainer}>
          <NumericInputField
            id="total"
            kind="cabinet"
            label="Баланс (в интеграциях)"
            value={balance}
            onChange={handleBalanceChange}
            error={errorMessage}
            disabled={role === RoleType.ADMIN}
          />
        </div>
        {role === RoleType.SUPER_ADMIN && (
          <CabinetButton className={styles.CabinetButton} disabled={isLoading}>
            Сохранить
          </CabinetButton>
        )}
      </Flex>
    </form>
  )
}
