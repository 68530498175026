import React, { FC } from 'react'

import { OrderResponsesResponseDto } from 'src/shared/config/types'

import { Flex } from 'src/shared/ui/Flex'

import { CustomerMediaSections } from '../CustomerMediaSections/CustomerMediaSections'
import { ProgressStage } from '../ProgressStage/ProgressStage'

import styles from './WaitPayConfirmStage.module.scss'

interface WaitPayConfirmStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const WaitPayConfirmStage: FC<WaitPayConfirmStagePropsType> = ({ orderResponse }) => {
  return (
    <Flex className={styles.WaitPayConfirmStage} flexDirection="column">
      <CustomerMediaSections orderResponse={orderResponse} visibleStages={[1]} />
      <div className={styles.Title}>Подтверждение оплаты</div>
      <ProgressStage orderResponse={orderResponse} />
    </Flex>
  )
}
