import { useEffect, useMemo, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { AdminAPI, PaginateResultDto } from 'src/shared/api'
import { ProductAdminResponseDto, ProductStatusType } from 'src/shared/config/types'

import { getTotalCountByStatus } from '../lib/getTotalCountByStatus'

export type ProductsDto = PaginateResultDto & {
  totalPending: number
  totalConfirmed: number
  totalRejected: number
  data: ProductAdminResponseDto[]
}

export const useProducts = (status: ProductStatusType, search: string) => {
  const [productsCounts, setProductsCounts] = useState<Record<string, number>>({})

  /* TODO - Сделать отображение загрузки */
  const {
    data: productsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ProductsDto>({
    queryKey: ['products', status, search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.managementControllerFindAllProducts(
        {
          page: Number(pageParam),
          take: 20,
          status,
          search,
          _createAt: 'desc',
        },
        { format: 'json' },
      )

      return response.json()
    },
    getNextPageParam: (lastPage, pages) => {
      const total = getTotalCountByStatus(lastPage, status)

      const currentPage = pages.length
      const totalPages = Math.ceil(total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  useEffect(() => {
    if (productsData?.pages[0]) {
      const { data, ...newProductsCounts } = productsData.pages[0]

      if (newProductsCounts) {
        setProductsCounts(newProductsCounts)
      }
    }
  }, [productsData])

  const products = useMemo(() => productsData?.pages.flatMap((page) => page.data) || [], [productsData])

  return {
    products,
    productsCounts,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  }
}
