import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useInfiniteQuery } from '@tanstack/react-query'
import cn from 'classnames'
import { format } from 'date-fns'

import { useInfiniteScroll } from 'src/shared/model/useInfiniteScroll'

import { AdminAPI, BlogersOrderListResponseDto, PaginateResultDto } from 'src/shared/api'

import { Flex } from 'src/shared/ui/Flex'

import styles from './CompletedTasks.module.scss'

interface CompletedTasksProps {
  bloggerId: number
}

type BloggerOrdersResponse = Omit<PaginateResultDto, 'data'> & {
  data?: BlogersOrderListResponseDto[]
}

export const CompletedTasks: FC<CompletedTasksProps> = ({ bloggerId }) => {
  const navigate = useNavigate()

  const {
    data: response,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<BloggerOrdersResponse, Error>({
    queryKey: ['tasks', bloggerId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await AdminAPI.api.bloggerControllerGetBlogersOrders(bloggerId, {
        page: Number(pageParam),
        take: 20,
      })
      return response.data
    },
    getNextPageParam: (lastPage, pages) => {
      const currentPage = pages.length
      const totalPages = Math.ceil(lastPage.total / 20)
      return currentPage < totalPages ? currentPage + 1 : undefined
    },
    initialPageParam: 1,
  })

  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  const tasks = useMemo(() => response?.pages.flatMap((page) => page.data), [response])

  const openTask = useCallback((taskId: number) => navigate(`/cabinet/blogger/${bloggerId}/task/${taskId}`), [navigate])

  return (
    <Flex flexDirection="column" gap={12}>
      {tasks?.map((task, index) => {
        if (!task) {
          return null
        }
        return (
          <Flex className={styles.Task} flexDirection="column" onClick={() => openTask(task.orderId)} key={index}>
            <div className={styles.Id}>{task.orderId}</div>
            <Flex className={styles.Header}>
              <Flex className={styles.Main} alignItems="center">
                <img className={styles.Photo} src={task.photoUrl} />
                <Flex flexDirection="column" gap={12}>
                  <span className={styles.Title}>{task.title}</span>
                  <Flex flexDirection="column">
                    <span className={styles.Text}>Цена товара: {task.price} ₽</span>
                    <span className={styles.Text}>Дата завершения: {format(task.finishDate, 'dd.MM.yyyy')}</span>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )
      })}
      {isFetchingNextPage && <div className={cn('loader', styles.Loader)}></div>}
    </Flex>
  )
}
