import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'

import { useCustomerProfile } from 'src/shared/model/useCustomerProfile'
import { useUserInfo } from 'src/shared/model/useUser'

import { RoleType } from 'src/shared/config/types'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { AdminProfileForm } from '../AdminProfileForm/AdminProfileForm'
import { BloggerProfile } from '../BloggerProfile/BloggerProfile'
import { CustomerProfileForm } from '../CustomerProfileForm/CustomerProfileForm'

import styles from './CabinetProfile.module.scss'

export const CabinetProfile: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])
  const { user } = useUserInfo()
  const profile = useCustomerProfile()

  const isBloggerProfile = user?.role === RoleType.BLOGER
  const isSuperAdminProfile = user?.role === RoleType.SUPER_ADMIN || user?.role === RoleType.ADMIN
  const isCustomerProfile = user?.role === RoleType.CUSTOMER

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Личный кабинет</PageTitle>
      </Flex>
      {isBloggerProfile && <BloggerProfile />}
      {isCustomerProfile && <CustomerProfileForm profile={profile} key={`profile-${profile?.id}`} />}
      {isSuperAdminProfile && <AdminProfileForm />}
    </CabinetPageLayout>
  )
}
