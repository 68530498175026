import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { useUserInfo } from 'src/shared/model/useUser'

import { useSocket } from 'src/app/lib/SocketProvider'
import { CABINET_NOTIFICATIONS_NEW_ROUTE } from 'src/shared/config/consts'
import { RoleType } from 'src/shared/config/types'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { groupNotificationsByTime } from '../../lib/groupNotificationsByTime'
import { Notification, NotificationType } from '../Notification/Notification'

import styles from './CabinetNotifications.module.scss'

type Notifications = {
  platform: NotificationType[] | []
  technical: NotificationType[] | []
}

export const CabinetNotifications: FC = () => {
  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const [notifications, setNotifications] = useState<Notifications>({ platform: [], technical: [] })

  const socket = useSocket()

  useEffect(() => {
    if (socket) {
      socket.emit('notification:get')

      socket.on('notification:update', (data: Notifications) => {
        setNotifications(data)
      })
    }

    return () => {
      socket?.off('notification:update')
    }
  }, [socket])

  const groupedTechnicalNotifications = groupNotificationsByTime(notifications.technical)

  const handleAddNotificationClick = useCallback(() => navigate(CABINET_NOTIFICATIONS_NEW_ROUTE), [navigate])

  return (
    <CabinetPageLayout className={styles.Layout}>
      <header className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <Flex justifyContent="space-between" className={styles.HeaderText}>
          <PageTitle>Уведомления</PageTitle>
          {role === RoleType.SUPER_ADMIN && (
            <CabinetGhostButton onClick={handleAddNotificationClick} icon="plus" className={styles.AddButton}>
              Создать
            </CabinetGhostButton>
          )}
        </Flex>
      </header>

      <div className={styles.Main}>
        {/* PLATFORM уведомления */}
        <section className={styles.Group}>
          {notifications.platform.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        </section>

        {/* TECHNICAL уведомления с разделением по времени */}
        {Object.entries(groupedTechnicalNotifications).map(([timeGroup, notifications]) => (
          <section className={styles.Group} key={timeGroup}>
            <h3 className={styles.GroupTitle}>{timeGroup}</h3>
            {notifications.map((notification) => (
              <Notification key={notification.id} {...notification} />
            ))}
          </section>
        ))}
      </div>
    </CabinetPageLayout>
  )
}
