import { isThisMonth, isThisWeek, isToday, isYesterday, parseISO } from 'date-fns'

import { NotificationType } from '../ui/Notification/Notification'

export const groupNotificationsByTime = (notifications: NotificationType[]) => {
  return notifications?.reduce(
    (groups, notification) => {
      const createAt = parseISO(notification.createAt)
      let groupKey = 'Старые'

      if (isToday(createAt)) {
        groupKey = 'Сегодня'
      } else if (isYesterday(createAt)) {
        groupKey = 'Вчера'
      } else if (isThisWeek(createAt)) {
        groupKey = 'На этой неделе'
      } else if (isThisMonth(createAt)) {
        groupKey = 'В этом месяце'
      }

      if (!groups[groupKey]) {
        groups[groupKey] = []
      }

      groups[groupKey].push(notification)
      return groups
    },
    {} as Record<string, NotificationType[]>,
  )
}
