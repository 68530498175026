import React, { FC, useCallback } from 'react'
import { formatISO, parse } from 'date-fns'

import { useUserInfo } from 'src/shared/model/useUser'

import { CustomReporterType, OrderResponsesResponseDto, RoleType } from 'src/shared/config/types'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import styles from './ProgressStage.module.scss'

interface ProgressStageProps {
  orderResponse: OrderResponsesResponseDto
}

export const ProgressStage: FC<ProgressStageProps> = ({ orderResponse }) => {
  const { role } = useUserInfo()
  const isAdmin = role === RoleType.SUPER_ADMIN

  const convertDate = useCallback((inputDate: string) => {
    const parsedDate = parse(inputDate, 'dd.MM.yyyy', new Date())
    return formatISO(parsedDate, { representation: 'complete' })
  }, [])

  let stageConfig = null

  if (orderResponse.status === CustomReporterType.WAIT_PAYMENT) {
    stageConfig = {
      time: orderResponse.firstStageTime || '',
      duration: 48,
      label: 'Оставшееся время для совершения оплаты:',
      error: 'Время истекло. Пожалуйста, отправьте оплату как можно скорее',
      showLabelAfterTimeOver: isAdmin,
      showErrorAfterTimeOver: false,
    }
    if (isAdmin) {
      stageConfig.label = 'Блогер находится на этом этапе. \nПодождите, пока селлер произведёт оплату'
      stageConfig.error = 'Время истекло.'
      if (orderResponse.comments?.bloggers[0]) {
        stageConfig.label = 'Блогер оставил комментарий для селлера! \nПодождите, пока селлер разберётся'
      }
    }
  }

  if (orderResponse.status === CustomReporterType.WAIT_PAY_CONFIRM) {
    stageConfig = {
      time: orderResponse.updateAt,
      duration: 24,
      label: 'Блогер находится на этом этапе. \nПодождите, пока он подтвердит оплату',
      error: isAdmin
        ? 'Блогер не подтвердил оплату в срок. \nПожалуйста свяжитесь с ним, и уточните причину'
        : 'Не волнуйтесь, блогер скоро подтвердит оплату. \nЕсли этого не произойдет в ближайшие 48 часов, \nобратитесь в поддержку',
    }
  }

  if (orderResponse.status === CustomReporterType.PAID) {
    stageConfig = {
      time: orderResponse.updateAt,
      duration: 168,
      label: 'Блогер находится на этом этапе. \nПодождите, пока он его выполнит.',
      error: 'Время вышло',
    }
  }

  if (orderResponse.status === CustomReporterType.COMPLETED_STEP_2) {
    stageConfig = {
      time: convertDate(orderResponse.adDate || ''),
      duration: 71.9999,
      label: 'Блогер находится на этом этапе. \nПодождите, пока он его выполнит.',
      error: 'Время вышло',
    }
  }

  if (!stageConfig) return null

  return <RemainingTime {...stageConfig} className={styles.RemainingTime} />
}
