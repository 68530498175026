import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, EditReportDto, HttpResponse } from 'src/shared/api'

export const useEditResponseMutation = (reportId: number) => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    EditReportDto
  >({
    mutationFn: (data) => AdminAPI.api.reportManagementControllerEditResponse(reportId, data),
  })
}
