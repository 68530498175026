import React, { FC } from 'react'

import { Accordion } from 'src/shared/ui/Accordion'
import { Flex } from 'src/shared/ui/Flex'

import styles from './CommentsAccordion.module.scss'

interface CommentsAccordionProps {
  comments?: {
    bloggers: string[]
    customers: string[]
  }
}

const CommentSection: FC<{ title: string; comments: string[] }> = ({ title, comments }) => (
  <Flex flexDirection="column" gap={8}>
    <p className={styles.CommentTitle}>{title}</p>
    {comments.map((comment, index) => (
      <div key={index} className={styles.Comment}>
        {comment}
      </div>
    ))}
  </Flex>
)

export const CommentsAccordion: FC<CommentsAccordionProps> = ({ comments }) => {
  const { bloggers = [], customers = [] } = comments || {}

  return (
    <Accordion
      className={styles.CommentsAccordion}
      data={[
        {
          title: 'История комментариев',
          text: (
            <Flex flexDirection="column" gap={32}>
              {bloggers.length > 0 && <CommentSection title="От блогера:" comments={bloggers} />}
              {customers.length > 0 && <CommentSection title="От селлера:" comments={customers} />}
            </Flex>
          ),
        },
      ]}
      kind="cabinet"
    />
  )
}
