import React, { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { AdminAPI } from 'src/shared/api'
import { ReactComponent as Star } from 'src/shared/assets/star.svg'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { TextareaField } from 'src/shared/ui/TextareaField'

import { BloggerInfoSection, CustomerMediaSections } from 'src/widgets/CabinetIntegrationPageLayout'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './CabinetUserTask.module.scss'

export const CabinetUserTask: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const { bloggerId, taskId } = useParams()

  const { data: response } = useQuery({
    queryKey: ['blogger', bloggerId, 'task', taskId],
    queryFn: () => AdminAPI.api.bloggerControllerGetBlogersOrdersById(Number(bloggerId), Number(taskId)),
  })

  const task = response?.data
  const rating = task?.rating

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <Flex className={styles.Header}>
          <Flex flexDirection="column" gap={8}>
            <PageTitle>{`Интеграция №${taskId}`}</PageTitle>
          </Flex>
        </Flex>
        {task && (
          <Flex flexDirection="column" gap={16}>
            <BloggerInfoSection blogger={task.bloger} className={styles.Blogger} />
            <Flex className={styles.Section} flexDirection="column">
              {/* TODO - Разобраться с типами */}
              {/* @ts-ignore */}
              <CustomerMediaSections orderResponse={task} />
            </Flex>

            {rating && (
              <Flex className={styles.Section} flexDirection="column" gap={16}>
                <h2 className={styles.Title}>Оценка интеграции</h2>
                <Field label="Качество работы блогера:" id="rating" kind="cabinet" className={styles.Rating}>
                  <Flex gap={4}>
                    <span>{rating.ratingQuality}</span> <Star />
                  </Flex>
                </Field>
                <Field label="Скорость работы блогера:" id="rating" kind="cabinet" className={styles.Rating}>
                  <Flex gap={4}>
                    <span>{rating.ratingSpeed}</span> <Star />
                  </Flex>
                </Field>
                <Field label="Качество рекламы:" id="rating" kind="cabinet" className={styles.Rating}>
                  <Flex gap={4}>
                    <span>{rating.ratingAdQuality}</span> <Star />
                  </Flex>
                </Field>

                {Boolean(rating.comment) && (
                  <TextareaField
                    label="Комментарий:"
                    id="comment"
                    kind="cabinet"
                    disabled
                    className={styles.Comment}
                    value={rating.comment}
                  />
                )}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </CabinetPageLayout>
  )
}
