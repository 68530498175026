import { ProductStatusType } from 'src/shared/config/types'

import { ProductsDto } from '../model/useProducts'

/**
 * Функция для получения общего количества продуктов в зависимости от статуса.
 * @param lastPage - последняя загруженная страница с данными.
 * @param status - текущий статус товара.
 * @returns общее количество товаров для заданного статуса.
 */
export const getTotalCountByStatus = (lastPage: ProductsDto, status: ProductStatusType): number => {
  switch (status) {
    case ProductStatusType.CONFIRMED:
      return lastPage.totalConfirmed
    case ProductStatusType.PENDING:
      return lastPage.totalPending
    case ProductStatusType.REJECTED:
      return lastPage.totalRejected
    default:
      return lastPage.total
  }
}
