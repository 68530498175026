import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, HttpResponse, RemoveReportManagementDto } from 'src/shared/api'
import { RoleType } from 'src/shared/config/types'

import { Icon } from 'src/shared/ui/Icon'

import { DeleteReportModal } from '../DeleteReportModal/DeleteReportModal'

import styles from './DeleteReportButton.module.scss'

interface DeleteReportButtonProps {
  reportId?: number
  orderId?: number
}

export const DeleteReportButton: FC<DeleteReportButtonProps> = ({ reportId, orderId }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const { user } = useUserInfo()

  if (user?.role !== RoleType.SUPER_ADMIN || !reportId || !orderId) {
    return null
  }

  const deleteReportMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    RemoveReportManagementDto
  >({
    mutationFn: async (data: RemoveReportManagementDto) =>
      AdminAPI.api.reportManagementControllerDelete(reportId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders', orderId] })
    },
  })

  const isLoading = deleteReportMutation.isPending

  const deleteReport = useCallback(
    (isRefund: boolean) => {
      const addMarketplaceToastId = toast.loading('Удаляем отклик пользователя')

      deleteReportMutation.mutate(
        { isRefund },
        {
          onSuccess: () => {
            toast.success('Отклик удалён!', {
              id: addMarketplaceToastId,
            })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Ошибка при удалении отклика', {
              id: addMarketplaceToastId,
            })
          },
        },
      )
    },
    [reportId, deleteReportMutation],
  )

  const handleRejectModalClose = useCallback(() => setModalOpen(false), [])

  return (
    <>
      <button className={styles.DeleteReportButton} disabled={isLoading} onClick={() => setModalOpen(true)}>
        <Icon name="close" />
      </button>
      {isModalOpen && <DeleteReportModal onDelete={deleteReport} onClose={handleRejectModalClose} />}
    </>
  )
}
