import React, { FC } from 'react'

import { BankDetailsDto, TopicResponseDto, UserResponseDto } from 'src/shared/api'

import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'
import { NumericInputField } from 'src/shared/ui/NumericInputField'
import { PhoneInputField } from 'src/shared/ui/PhoneInputField'
import { TextInputField } from 'src/shared/ui/TextInputField'

import { Profile } from 'src/widgets/Profile'

import styles from './PersonalInfo.module.scss'

interface PersonalInfoProps {
  user: UserResponseDto
}

export const PersonalInfo: FC<PersonalInfoProps> = ({ user }) => {
  const { bloger: blogger } = user

  const verifyInfo = blogger?.verifyInfo
  const bankDetails = blogger?.bankDetails as unknown as BankDetailsDto[]
  const topics = blogger?.topics as unknown as TopicResponseDto[]

  return (
    <Flex className={styles.Blocks}>
      <Profile user={user} className={styles.Block} />
      <Flex flexDirection="column" gap={32} className={styles.Block}>
        <h2 className={styles.Title}>Данные блога</h2>
        <TextInputField
          value={blogger?.instagram}
          id="instagram"
          name="instagram"
          label="Ссылка на Instagram"
          kind="cabinet"
          readOnly
        />
        <NumericInputField
          value={blogger?.followersCount}
          id="followersCount"
          name="followersCount"
          label="Количество подписчиков"
          kind="cabinet"
          readOnly
        />
        <NumericInputField
          value={blogger?.coveragesCount}
          id="coveragesCount"
          name="coveragesCount"
          label="Количество охватов"
          kind="cabinet"
          readOnly
        />
        <Field id="topics" label="Тематика блога:" kind="cabinet">
          <div className={styles.MultiSelectFormField}></div>
          {topics.map((topic) => {
            return (
              <TextInputField
                key={topic.id}
                value={topic.name}
                id={String(topic.id)}
                name={topic.name}
                kind="cabinet"
                readOnly
              />
            )
          })}
        </Field>
      </Flex>

      <Flex flexDirection="column" gap={24} className={styles.Block}>
        <h2 className={styles.Title}>Скриншоты и видео статистики</h2>
        <Flex flexWrap="wrap" gap={16}>
          <Media
            type="video"
            src={verifyInfo?.videoStatistics}
            description="Видеозапись экрана общей статистики просмотров за 7,14,30 дней"
          />
          <Media
            type="image"
            src={verifyInfo?.genderStatistics}
            description="Скриншот статистики возраста аудитории и пола"
          />
          <Media
            type="image"
            src={verifyInfo?.countriesStatistics}
            description="Скриншот статистики городов аудитории"
          />
          <Media
            type="image"
            src={verifyInfo?.lastStoryStatistics}
            description="Скриншот статистики последней истории, где прошло 24 ч и более"
          />
          <Media
            type="image"
            src={verifyInfo?.publicationStatistics}
            description="Скриншот статистики последнего поста, где прошло 24ч и более"
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={24} className={styles.Block}>
        <h2 className={styles.Title}>Банковские реквизиты</h2>
        {bankDetails.map((item, index) => {
          return (
            <Flex key={index} flexDirection="column" gap={32}>
              <TextInputField
                value={item.accountNumber}
                id={`${index}.accountNumber`}
                name={`${index}.accountNumber`}
                label="Номер карты"
                kind="cabinet"
                readOnly
              />
              <PhoneInputField
                value={item.accountPhone}
                id={`${index}.accountPhone`}
                name={`${index}.accountPhone`}
                label="Номер телефона"
                kind="cabinet"
                readOnly
              />
              <TextInputField
                value={item.bankName}
                id={`${index}.bankName`}
                name={`${index}.bankName`}
                label="Название банка"
                kind="cabinet"
                readOnly
              />
              <TextInputField
                value={item.names}
                id={`${index}.names`}
                name={`${index}.names`}
                label="ФИО"
                kind="cabinet"
                readOnly
              />
              {index + 1 < bankDetails.length && (
                <div className={styles.Separator}>
                  <hr />
                  <span>или</span>
                  <hr />
                </div>
              )}
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}
