import React, { FC, useCallback, useMemo, useState } from 'react'
import { DeepPartial, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'

import { BankDetailsDto, BlogerResponseDto, TopicResponseDto, UpsertBloggerDto } from 'src/shared/api'

import { getBankDetailsChange, getTopicsChanges } from 'src/shared/lib/prepareBloggerFields'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'

import { BankDetailsSection, BloggerForm, BlogInfo, MediaList, PersonalInfo } from 'src/widgets/BloggerForm'
import { BloggerFormFields } from 'src/widgets/BloggerForm/ui/BloggerForm/BloggerForm'

import { useBloggerUpdateMutation } from '../../api/useBloggerUpdateMutation'

import styles from './BloggerProfileData.module.scss'

interface BloggerProfileDataProps {
  profile?: BlogerResponseDto
}

const UPDATE_FORM_ID = 'BloggerUpdate'
const VERIFY_DATA_UPDATE_FORM_ID = 'BloggerVerifyDataUpdate'

export const BloggerProfileData: FC<BloggerProfileDataProps> = ({ profile }) => {
  const queryClient = useQueryClient()
  const [isUploading, setIsUploading] = useState(false)

  if (!profile) {
    return null
  }

  const bankDetails = profile.bankDetails as unknown as BankDetailsDto[]
  const topics = profile.topics as unknown as TopicResponseDto[]

  const defaultValues = useMemo<DeepPartial<BloggerFormFields>>(
    () => ({
      country: profile.country,
      city: profile.city,
      instagram: profile.instagram,
      topics: topics.map((item) => ({ topicId: String(item.id) })),
      bankDetails: bankDetails,
    }),
    [profile],
  )

  const methods = useForm<BloggerFormFields>({ defaultValues })

  const verifyDataUpdateMethods = useForm<BloggerFormFields>()
  const { watch } = verifyDataUpdateMethods

  const { mutate: bloggerUpdateMutation, isPending: isLoading } = useBloggerUpdateMutation()

  function getUpdatedValue<T>(defaultValue: T, newValue: T): T | undefined {
    return defaultValue === newValue ? undefined : newValue
  }

  const getUpdateBloggerData = (data: BloggerFormFields) => {
    const { newTopics, deletedTopics } = getTopicsChanges(topics, data.topics)
    const newBankDetails = getBankDetailsChange(bankDetails, data.bankDetails)

    const updateBloggerDto: UpsertBloggerDto = {
      country: getUpdatedValue(defaultValues.country, data.country),
      city: getUpdatedValue(defaultValues.city, data.city),
      instagram: getUpdatedValue(defaultValues.instagram, data.instagram),
      blogTopics: {
        create: newTopics.map(({ topicId }) => ({ topicId: Number(topicId) })),
        delete: deletedTopics.map((topicId) => ({ topicId: Number(topicId) })),
      },
      bankDetails: newBankDetails as BankDetailsDto[],
    }
    return updateBloggerDto
  }

  const getUpdateVerifyBloggerData = (data: BloggerFormFields) => {
    const newVerifyInfo = data.verifyInfo

    const updateBloggerDto: UpsertBloggerDto = {
      followersCount: getUpdatedValue(profile.followersCount, Number(data.followersCount)),
      coveragesCount: getUpdatedValue(profile.coveragesCount, Number(data.coveragesCount)),
      verifyInfo: {
        videoStatistics: newVerifyInfo?.videoStatistics,
        genderStatistics: newVerifyInfo?.genderStatistics,
        countriesStatistics: newVerifyInfo?.countriesStatistics,
        lastStoryStatistics: newVerifyInfo?.lastStoryStatistics,
        publicationStatistics: newVerifyInfo?.publicationStatistics,
      },
    }
    return updateBloggerDto
  }

  const bloggerUpdateData = useCallback(
    (data: BloggerFormFields) => {
      const updateUserToastId = toast.loading('Обновляем данные')

      const updateBloggerDto = data.followersCount ? getUpdateVerifyBloggerData(data) : getUpdateBloggerData(data)

      bloggerUpdateMutation(updateBloggerDto, {
        onSuccess: () => {
          toast.success(
            data.followersCount ? 'Заявка на изменение данных отправлена! ✨' : 'Пользовательские данные обновлены! ✨',
            {
              id: updateUserToastId,
            },
          )
          queryClient.invalidateQueries({ queryKey: ['profile'] })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при обновлении пользовательских данных', {
            id: updateUserToastId,
          })
        },
      })
    },
    [getUpdatedValue],
  )

  const isSumbitButtonDisable = isLoading || isUploading

  const isVerifyUpdateSumbitButtonDisable =
    isLoading ||
    isUploading ||
    !watch('followersCount') ||
    !watch('coveragesCount') ||
    !watch('verifyInfo.videoStatistics') ||
    !watch('verifyInfo.countriesStatistics') ||
    !watch('verifyInfo.genderStatistics') ||
    !watch('verifyInfo.lastStoryStatistics') ||
    !watch('verifyInfo.publicationStatistics')

  return (
    <Flex flexDirection="column" gap={56} className={styles.Layout}>
      <Flex flexDirection="column" gap={12}>
        <BloggerForm
          formId={UPDATE_FORM_ID}
          methods={methods}
          isLoading={isLoading}
          setIsUploading={setIsUploading}
          onSubmit={bloggerUpdateData}
        >
          <PersonalInfo showCountry />
          <BlogInfo showInstagram showTopics />
          <BankDetailsSection description="Укажите информацию для получения оплаты от селлеров" />
        </BloggerForm>
        <CabinetButton form={UPDATE_FORM_ID} disabled={isSumbitButtonDisable} className={styles.FormButton}>
          Сохранить
        </CabinetButton>
      </Flex>

      <Flex flexDirection="column" gap={12}>
        <div className={styles.Comment}>
          ❗Заявку на изменение информации ниже можно отправлять не чаще одного раза в месяц. Все заявки будут
          рассмотрены администратором.
        </div>
        <BloggerForm
          formId={VERIFY_DATA_UPDATE_FORM_ID}
          methods={verifyDataUpdateMethods}
          isLoading={isLoading}
          setIsUploading={setIsUploading}
          onSubmit={bloggerUpdateData}
        >
          <BlogInfo showCoverages showFollowers />
          <MediaList isOnlyEditable />
        </BloggerForm>
        <CabinetButton
          form={VERIFY_DATA_UPDATE_FORM_ID}
          disabled={isVerifyUpdateSumbitButtonDisable}
          className={styles.FormButton}
        >
          Отправить заявку
        </CabinetButton>
      </Flex>
    </Flex>
  )
}
