import { FC, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { AdminAPI, UserResponseDto } from 'src/shared/api'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { CompletedTasks } from '../CompletedTasks/CompletedTasks'
import { PersonalInfo } from '../PersonalInfo/PersonalInfo'

import styles from './CabinetUser.module.scss'

enum TabType {
  PERSONAL = 'PERSONAL',
  COMPLETED = 'COMPLETED',
}

export const CabinetUser: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTabId = searchParams.get('tab') || TabType.PERSONAL

  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const handleTabClick = useCallback(
    (newTabId: TabType) => {
      setSearchParams({ tab: newTabId }, { replace: true })
    },
    [setSearchParams],
  )

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.PERSONAL,
        title: 'Личные данные',
      },
      {
        tabId: TabType.COMPLETED,
        title: 'Завершённые задания',
      },
    ],
    [],
  )

  const { userId: userIdParam } = useParams()
  const userId = Number(userIdParam)

  const { data: userData } = useQuery({
    queryKey: ['users', userId],
    queryFn: () => AdminAPI.api.bloggerControllerGetProfileByUserId(userId, { format: 'json' }),
  })

  const user = userData?.data as UserResponseDto
  const bloggerId = user?.bloger?.id

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Профиль блогера</PageTitle>
      </Flex>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={handleTabClick} />
      {activeTabId === TabType.PERSONAL && user && <PersonalInfo user={user} />}
      {activeTabId === TabType.COMPLETED && bloggerId && <CompletedTasks bloggerId={bloggerId} />}
    </CabinetPageLayout>
  )
}
