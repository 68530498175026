import React, { FC, useCallback } from 'react'
import cn from 'classnames'

import { UserResponseDto } from 'src/shared/api'
import { ReactComponent as LaurelAwardLeft } from 'src/shared/assets/laurel_award_left.svg'
import { ReactComponent as LaurelAwardRight } from 'src/shared/assets/laurel_award_right.svg'
import { RoleType } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { Avatar } from 'src/shared/ui/Avatar'
import { Flex } from 'src/shared/ui/Flex'
import { PhoneInputField } from 'src/shared/ui/PhoneInputField'
import { TextInputField } from 'src/shared/ui/TextInputField'

import styles from './Profile.module.scss'

interface ProfilePropsType {
  user: UserResponseDto
  className?: string
}

export const Profile: FC<ProfilePropsType> = ({ user, className }) => {
  const { avatar, firstName, lastName, phone, role } = user

  const renderUserFields = useCallback(() => {
    if (role === RoleType.CUSTOMER) {
      const { customer } = user

      return <TextInputField id="inn" kind="cabinet" label="ИНН" value={customer?.inn || ''} readOnly />
    }

    if (role === RoleType.BLOGER) {
      const { bloger } = user
      return (
        <>
          <TextInputField
            value={bloger?.country}
            id="country"
            name="country"
            label="Страна"
            kind="cabinet"
            disabled={true}
          />
          <TextInputField value={bloger?.city} id="city" name="city" label="Город" kind="cabinet" disabled={true} />
        </>
      )
    }
  }, [user])

  const rating = user.bloger?.rating || 0
  const completedTasksCount = user.bloger?.completedTasksCount || 0

  return (
    <Flex className={cn(styles.InputsContainer, className)} flexDirection="column">
      <Flex gap={24} alignItems="center">
        <Avatar className={styles.Avatar} avatar={avatar} firstName={firstName} />
        <Flex flexDirection="column" gap={12}>
          {Boolean(rating) && (
            <Flex gap={5}>
              <LaurelAwardLeft />
              <div className={styles.RatingDetails}>
                <span className={styles.RatingValue}>{rating}</span>
                <span className={styles.RatingDescription}>Рейтинг</span>
              </div>
              <LaurelAwardRight />
            </Flex>
          )}
          {Boolean(completedTasksCount) && (
            <Flex gap={5}>
              <LaurelAwardLeft />
              <div className={styles.RatingDetails}>
                <span
                  className={styles.RatingValue}
                >{`${completedTasksCount} ${plural(['задание', 'задания', 'заданий'], completedTasksCount)}`}</span>
                <span className={styles.RatingDescription}>Выполнил блогер</span>
              </div>
              <LaurelAwardRight />
            </Flex>
          )}
        </Flex>
      </Flex>
      <TextInputField id="firstName" kind="cabinet" label="Имя" value={firstName} readOnly />
      <TextInputField id="lastName" kind="cabinet" label="Фамилия" value={lastName || ''} readOnly />

      <PhoneInputField id="phone" kind="cabinet" label="Телефон" value={phone} readOnly />
      {renderUserFields()}
    </Flex>
  )
}
