import { QueryClient, useMutation } from '@tanstack/react-query'

import { AdminAPI, ApproveChangesDto, CommonMessageResponse, HttpResponse } from 'src/shared/api'

export const useChangeRequestStatusMutation = (queryClient: QueryClient) => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { userId: number; data: ApproveChangesDto }
  >({
    mutationFn: ({ userId, data }) => AdminAPI.api.usersManagementControllerApproveBlogerChanges(userId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
  })
}
