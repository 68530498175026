import { useQuery } from '@tanstack/react-query'

import { AdminAPI } from '../api'
import { CustomerProfileDto, RoleType } from '../config/types'

import { useUserInfo } from './useUser'

export const useCustomerProfile = () => {
  const { role } = useUserInfo()

  const { data: profile } = useQuery<CustomerProfileDto | undefined>({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await AdminAPI.api.customersControllerFindOne({
        format: 'json',
      })

      return response.json()
    },
    enabled: role === RoleType.CUSTOMER,
  })

  return profile
}
