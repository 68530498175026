import { FC } from 'react'
import { createPortal } from 'react-dom'
import { Link } from 'react-router-dom'
import { useKey } from 'react-use'

import { useCustomerProfile } from 'src/shared/model/useCustomerProfile'
import { useUserInfo } from 'src/shared/model/useUser'

import {
  CABINET_NOTIFICATIONS_ROUTE,
  CABINET_PAYMENT_ROUTE,
  CABINET_PROFILE_ROUTE,
  CABINET_SETTINGS_ROUTE,
} from 'src/shared/config/consts'
import { RoleType } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { Flex } from 'src/shared/ui/Flex'
import { Icon } from 'src/shared/ui/Icon'

import { Switch } from 'src/widgets/Switch'

import { Logo } from '../Logo/Logo'
import { SupportLink } from '../SupportLink/SupportLink'
import { TelegramLink } from '../TelegramLink/TelegramLink'

import styles from './MenuContent.module.scss'

interface MenuContentPropsType {
  navigationItems: { title: string; to: string }[]
  onClose(): void
}

export const MenuContent: FC<MenuContentPropsType> = ({ navigationItems, onClose }) => {
  const { user } = useUserInfo()
  const customerProfile = useCustomerProfile()

  useKey('Escape', onClose, {}, [onClose])

  return createPortal(
    <Flex className={styles.MenuContent} flexDirection="column" isFluid>
      <Flex className={styles.Header} alignItems="center" flexShrink={0}>
        <Logo onClick={onClose} />
        <Icon className={styles.CloseIcon} name="close" onClick={onClose} />
      </Flex>
      <Flex className={styles.Container} flexDirection="column" gap={40} isFluid>
        <Flex flexDirection="column" gap={40}>
          {navigationItems.map(({ title, to }) => (
            <Link key={to} className={styles.NavigationItem} onClick={onClose} to={to}>
              {title}
            </Link>
          ))}
          {user?.role === RoleType.CUSTOMER && customerProfile && (
            <Link to={CABINET_PAYMENT_ROUTE} className={styles.NavigationItem} onClick={onClose}>
              <Flex flexDirection="column">
                <span className={styles.NavigationItem}>Мой баланс:</span>
                <span className={styles.Balance}>
                  {customerProfile.total || 0}{' '}
                  {plural(['интеграция', 'интеграции', 'интеграций'], customerProfile.total || 0)}
                </span>
              </Flex>
            </Link>
          )}
        </Flex>
        <div className={styles.Line} />
        <Flex flexDirection="column" gap={40}>
          <Link className={styles.NavigationItem} onClick={onClose} to={CABINET_PROFILE_ROUTE}>
            Личный кабинет
          </Link>
          <Link className={styles.NavigationItem} onClick={onClose} to={CABINET_SETTINGS_ROUTE}>
            Настройки
          </Link>
          <Link className={styles.NavigationItem} onClick={onClose} to={CABINET_NOTIFICATIONS_ROUTE}>
            Уведомления
          </Link>
        </Flex>
        <div className={styles.Line} />
        <Flex flexDirection="column" alignItems="start">
          <Switch CustomerComponent={TelegramLink} BloggerComponent={SupportLink} />
        </Flex>
      </Flex>
    </Flex>,
    document.body,
  )
}
