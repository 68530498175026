import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale/ru'

import { useUserInfo } from 'src/shared/model/useUser'

import { CustomReporterType, OrderResponsesResponseDto, RoleType } from 'src/shared/config/types'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { AdminMediaSections } from '../AdminMediaSections/AdminMediaSections'
import { BloggerInfoSection } from '../BloggerInfoSection/BloggerInfoSection'
import { BloggerRatingForm } from '../BloggerRatingForm/BloggerRatingForm'
import { CommentsAccordion } from '../CommentsAccordion/CommentsAccordion'
import { CustomerMediaSections } from '../CustomerMediaSections/CustomerMediaSections'
import { WaitPayConfirmStage } from '../WaitPayConfirmStage/WaitPayConfirmStage'
import { WaitPaymentStage } from '../WaitPaymentStage/WaitPaymentStage'

import styles from './CabinetIntegrationPageLayout.module.scss'

interface CabinetIntegrationPageLayoutPropsType {
  orderResponse: OrderResponsesResponseDto
}

export const CabinetIntegrationPageLayout: FC<CabinetIntegrationPageLayoutPropsType> = ({ orderResponse }) => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const { role } = useUserInfo()
  const isAdmin = role === RoleType.SUPER_ADMIN
  const isCustomer = role === RoleType.CUSTOMER

  const openSupport = () => {
    window.open('https://t.me/marketiq_support', '_blank')
  }

  const renderStage = useCallback(() => {
    if (!orderResponse) {
      return null
    }

    if (isCustomer) {
      if (orderResponse.status === CustomReporterType.WAIT_PAYMENT) {
        return <WaitPaymentStage orderResponse={orderResponse} />
      }

      if (orderResponse.status === CustomReporterType.WAIT_PAY_CONFIRM) {
        return <WaitPayConfirmStage orderResponse={orderResponse} />
      }

      if (
        orderResponse.status === CustomReporterType.PAID ||
        orderResponse.status === CustomReporterType.COMPLETED_STEP_2 ||
        orderResponse.status === CustomReporterType.COMPLETED
      ) {
        return (
          <Flex className={styles.CompletedStage} flexDirection="column">
            <CustomerMediaSections orderResponse={orderResponse} />
          </Flex>
        )
      }
    }

    if (isAdmin) {
      return (
        <Flex className={styles.CompletedStage} flexDirection="column">
          <AdminMediaSections orderResponse={orderResponse} />
        </Flex>
      )
    }
  }, [role, orderResponse])

  const showCommentsAccordion =
    orderResponse.status !== CustomReporterType.WAIT_PAYMENT &&
    orderResponse.status !== CustomReporterType.PENDING &&
    orderResponse.comments &&
    isAdmin

  const showBloggerRatingForm = isCustomer && orderResponse.status === CustomReporterType.COMPLETED

  return (
    <CabinetPageLayout className={styles.CabinetIntegrationPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        {orderResponse && (
          <Flex flexDirection="column" isFluid>
            <Flex className={styles.Header}>
              <Flex flexDirection="column" gap={8}>
                <PageTitle>{`Интеграция №${orderResponse.id}`}</PageTitle>
                <span
                  className={styles.CreatedAt}
                >{`Создана ${format(orderResponse.createAt, 'dd MMMM yyyy', { locale: ru })}`}</span>
              </Flex>
              <CabinetGhostButton onClick={openSupport} className={styles.CabinetGhostButton}>
                Обратиться в поддержку
              </CabinetGhostButton>
            </Flex>
            <BloggerInfoSection blogger={orderResponse.bloger} />
            {showCommentsAccordion && <CommentsAccordion comments={orderResponse.comments} />}
            {renderStage()}
            {showBloggerRatingForm && <BloggerRatingForm hasRated={Boolean(orderResponse.ratingId)} />}
          </Flex>
        )}
      </Flex>
    </CabinetPageLayout>
  )
}
