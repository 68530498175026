import React, { FC, useCallback, useState } from 'react'
import cn from 'classnames'

import { Flex } from 'src/shared/ui/Flex'

import { MediaModal } from 'src/widgets/MediaModal'

import styles from './Media.module.scss'

interface MediaPropsType {
  className?: string
  src?: string
  description: string
  type: 'image' | 'video'
}

export const Media: FC<MediaPropsType> = ({ className, src, description, type }) => {
  const [isMediaOpen, setMediaOpen] = useState(false)

  const renderContent = useCallback(() => {
    if (!src) {
      return null
    }

    const handleClick = () => {
      setMediaOpen((prev) => !prev)
    }

    return type === 'image' ? (
      <img className={styles.Image} src={src} alt={description} onClick={handleClick} />
    ) : (
      <video className={styles.Video} src={src} controls />
    )
  }, [src, description, type])

  return (
    <>
      <Flex className={cn(styles.Media, className)} flexDirection="column" alignItems="center" gap={12}>
        <div className={styles.Preview}>{renderContent()}</div>
        <span className={styles.Description}>{description}</span>
      </Flex>
      {isMediaOpen && (
        <MediaModal src={src} description={description} type={type} onClose={() => setMediaOpen(false)} />
      )}
    </>
  )
}
