import React, { FC } from 'react'
import cn from 'classnames'

import { Avatar } from 'src/shared/ui/Avatar'
import { Flex } from 'src/shared/ui/Flex'

import styles from './BloggerInfoSection.module.scss'

interface BloggerInfoSectionProps {
  blogger: any
  className?: string
}
const numberFormat = new Intl.NumberFormat('ru')

export const BloggerInfoSection: FC<BloggerInfoSectionProps> = ({ blogger, className }) => {
  const tgUsername = blogger.tgUsername
  const formattedUsername = tgUsername?.startsWith('@') ? tgUsername.slice(1) : tgUsername

  return (
    <Flex className={cn(styles.Blogger, className)}>
      <Flex gap={12} alignItems="center">
        <Avatar className={styles.Avatar} avatar={blogger.avatar} firstName={blogger.firstName} />
        <Flex flexDirection="column" gap={4}>
          <span className={styles.FirstName}>{blogger.firstName}</span>
          <span className={styles.Item}>
            <span className={styles.Title}>Inst: </span>
            {blogger.instagram}
          </span>
          <span className={styles.Item}>
            <span className={styles.Title}>Tg: </span>
            {tgUsername ? (
              <a
                href={`https://t.me/${formattedUsername}`}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.Value, styles.TelegramLink)}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {'@' + tgUsername}
              </a>
            ) : (
              <span className={styles.Value}>Нет</span>
            )}
          </span>
          <span className={styles.Item}>{blogger.phone}</span>
        </Flex>
      </Flex>
      <Flex className={styles.Info}>
        <Flex className={styles.Item} flexDirection="column">
          <span className={styles.Title}>Подписчики:</span>
          <span className={styles.Value}>{numberFormat.format(blogger.followersCount)}</span>
        </Flex>
        <Flex className={styles.Item} flexDirection="column">
          <span className={styles.Title}>Средний охват:</span>
          <span className={styles.Value}>{numberFormat.format(blogger.coveragesCount)}</span>
        </Flex>
        <Flex className={styles.Item} flexDirection="column">
          <span className={styles.Title}>Тематика:</span>
          <Flex gap={4} className={styles.Tags}>
            {blogger.topics.map((item: { id: number; name: string }) => (
              <Flex key={item.name} className={styles.Tag} alignItems="center">
                {item.name}
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
