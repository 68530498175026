import React, { FC } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { AdminAPI, UserResponseDto } from 'src/shared/api'
import { RoleType, UserStatusType } from 'src/shared/config/types'

import { BlogerUser } from './BlogerUser/BlogerUser'
import { CustomerUser } from './CustomerUser/CustomerUser'
import { VerificationRequest } from './VerificationRequest/VerificationRequest'

export const AdminCabinetUser: FC = () => {
  const { userId: userIdParam } = useParams()
  const userId = Number(userIdParam)

  const { data: userData } = useQuery({
    queryKey: ['users', userId],
    // TODO Как будто необходимо избавится от дополнительных query параметров
    queryFn: () =>
      AdminAPI.api.usersManagementControllerGetUserById(userId, {
        ordersPage: 1,
        ordersTake: 20,
        productPage: 1,
        productTake: 20,
      }),
  })

  const user = userData?.data as UserResponseDto | undefined

  if (!user) {
    return null
  }

  if (user.role === RoleType.CUSTOMER) {
    return <CustomerUser user={user} />
  }

  if (user.role === RoleType.BLOGER) {
    if (user.status === UserStatusType.NOT_ACTIVATED || user.status === UserStatusType.INCOMPLETE_DATA) {
      return <VerificationRequest user={user} />
    } else {
      return <BlogerUser user={user} key={`user-${JSON.stringify(user)}`} />
    }
  }

  return null
}
